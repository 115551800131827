import {
  CompanyLogoH6,
  CompanyLogoMain,
  CompanyLogoP,
  CompanyLogoSub
} from "../../StyledComponents/HomeStyled";
import MarqueeLogo from "../Common/MarqueeLogo";

export default function CompanyLogo() {
  return (
    <CompanyLogoMain className="overflow-hidden re">
        
        <CompanyLogoSub className="">
          <div
            // initial={{ opacity: 0, y: 200 }}
            // transition={{ duration: 1, ease: [0, 0.71, 0.2, 1.01] }}
            // animate={{ opacity: 1, y: 0 }}
            className=" md:hidden "
          >
            <CompanyLogoH6>Trusted by top brands</CompanyLogoH6>
            <CompanyLogoP>including Fortune companies</CompanyLogoP>
            <MarqueeLogo />
          </div>
          <div
            // initial={{ opacity: 0, y: 200 }}
            // transition={{ duration: 1, ease: [0, 0.71, 0.2, 1.01] }}
            // animate={{ opacity: 1, y: 0 }}
            className="md:block hidden"
          >
            <CompanyLogoH6>Trusted by top brands</CompanyLogoH6>
            <CompanyLogoP>including Fortune companies</CompanyLogoP>
            <MarqueeLogo />
          </div>
        </CompanyLogoSub>
     
    </CompanyLogoMain>
  );
}
